import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./Home";

function App() {
  return <Home></Home>;
}

export default App;
