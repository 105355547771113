import React from "react";
import { Image, Button } from "react-bootstrap";
import "./App.css";
import Portrait from "./tinportrait.png";
import PDF from "./tinla_S22.pdf";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

function Home() {
  return (
    <div className="Background">
      <h1 className="Name">Tin La</h1>
      <div className="HomeContainer">
        <Image src={Portrait} className="Portrait" roundedCircle />
        <Button
          className="ResumeButton"
          href={PDF}
          size="lg"
          variant="outline-dark"
        >
          Resume
        </Button>
        <div className="IntroText">
          <p>Hi!</p>
          <p>
            I'm a computer science graduate of The University of Texas at
            Austin.
          </p>
          <p>
            I'm currently seeking fulltime opportunities for software
            engineering.
          </p>
          <p>I'm primarily interested in full stack and mobile development.</p>
        </div>

        <div className="MediaIcons">
          <a className="Icon1" href="//www.github.com/tintinator">
            <GitHubIcon fontSize="inherit" />
          </a>
          <a className="Icon2" href="//www.linkedin.com/in/tintinator">
            <LinkedInIcon fontSize="inherit" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
